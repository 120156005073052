import Layout from "../components/Layout";
import { defBreadcrumb } from "../data/DataBreadcrumb";
import NextHead from "../components/Seo/NextHead";
import { PageNotFoundBody } from "../components/PageNotFound";

const pageNotFound = ({ pageTitle, descriptionSEO, breadcrumb, asPath }) => {
  return (
    <Layout height="auto">
      <NextHead title={pageTitle} description={descriptionSEO} url={asPath} breadcrumb={breadcrumb}></NextHead>
      <PageNotFoundBody />
    </Layout>
  );
};

pageNotFound.getInitialProps = async ({asPath}) => {
  const pageTitle = "Mohon Maaf";
  const descriptionSEO = "Halaman yang anda cari tidak dapat ditemukan";

  const breadcrumb = [
    ...defBreadcrumb,
    {
      "@type": "ListItem",
      position: "2",
      item: {
        "@id": process.env.baseUrl + asPath,
        name: pageTitle
      }
    }
  ];

  return { pageTitle, descriptionSEO, breadcrumb, asPath };
};

export default pageNotFound;
